
	/**
	*
	* 		RESETS
	*			- These are browser reset files
	*
	**/

	//Anchor
	a,
	a:hover,
	a:active {
		outline: none;
		text-decoration:none;
	}

	//Reset the Lists
	li {
		list-style:none;
	}

	//Prevents "Back Swiping" in Chrome
	html.noswipe {
		&, body {
			overscroll-behavior-x:none;
		}
	}

