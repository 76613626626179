
	/**
	*
	* 		CONFIG
	*			- Site Config styles for the site
	*
	**/

	//Fonts
	$fonts: 		(
		'headline':	 		('Roboto Slab', serif),
		'primary': 			('Roboto', sans-serif)
	);

	//Colors
	$colors: 		(
		'brand': 		#0091CB,
		'highlight': 	#FAA519,
		'text': 		#000,
		'background': 	#fefefe,
	);

	//Primary Styles
	$primary: 	(
		min-width: 					320px,
		font-weight: 				400,
		line-height:				150%,
		font-size: 					16px,
		font-family: 				map-get($fonts, 'primary'),
		color: 						map-get($colors,'text'),
		overflow-x: 				hidden,
		background: 				map-get($colors,'background'),
		-webkit-font-smoothing: 	antialiased,
		-moz-osx-font-smoothing: 	grayscale,
		-webkit-text-size-adjust: 	100%,
		-ms-text-size-adjust: 		100%
	);
