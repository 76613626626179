
	/**
	*
	* 		BUTTONS
	*			- Add any button styles in here.
	* 			- Below is grouped into a buttons list but the .button is also accessible directly
	*
	**/

	//Single Button
	.button {
		appearance:none;
		user-select:none;
		display:inline-block;
		background:map-get($colors,'brand');
		font-size:clamp( 12px , 2.2vw , 14px );
		font-style:normal;
		font-weight:500;
		line-height:100%;
		letter-spacing:0.6px;
		text-transform:uppercase;
		padding:11px 18px;
		border:1px solid map-get($colors,'brand');
		color:#FFF;
		vertical-align:center;
		text-align:center;
		text-decoration:none;
		cursor:pointer;
		transition:0.3s ease background, 0.3s ease color, 0.3s ease border, 0.3s ease opacity;

		svg {
			position:relative;
			transition:0.3s ease-in-out transform;
			display:block;
			path {
				transition:0.3s ease stroke;
			}
		}

		&.arrow-left {
			svg {
				transform:rotate(180deg);
			}
			&:hover {
				svg {
					transform:rotate(180deg) translateX(2px);
				}
			}
		}

		&[disabled] {
			opacity:.5;
			background:#666 !important;
			cursor:not-allowed;
			border:1px solid #666 !important;
		}

		&.hidden {
			opacity:0;
			pointer-events:none !important;
		}

		&.hide {
			display:none;
		}

		&:hover {
			background:darken( map-get($colors,'brand') , 20 );
			border-color:darken( map-get($colors,'brand') , 20 );
			svg {
				transform:translateX(2px);
			}
		}

		&.small {
			padding:10px 20px;
			font-size:12px;
		}

		&.narrow {
			padding:5px 12px;
			font-size:12px;
			svg {
				path {
					stroke-width:1;
				}
			}
		}

		//White
		&.white:not(.inverted) {
			background:#FFF;
			color:map-get($colors,'text');
			border-color:#FFF;
			svg {
				path {
					stroke:map-get($colors,'text');
				}
			}
			&:hover {
				background:map-get($colors,'text');
				border-color:map-get($colors,'text');
				color:#FFF;
				svg {
					path {
						stroke:#FFF;
					}
				}
			}
		}

		//Dark
		&.dark:not(.inverted) {
			background:map-get($colors,'text');
			border-color:map-get($colors,'text');
			color:#FFF;
			&:hover {
				background:map-get($colors,'brand');
				border-color:map-get($colors,'brand')
			}
		}

		//With Arrow
		&.arrow {
			span {
				display:flex;
				align-items:center;
				justify-content:center;
				span:first-child {
					padding-right:10px;
				}
			}
		}

		//Inverted
		&.inverted {
			background:transparent;
			border-color:map-get($colors,'brand');
			color:map-get($colors,'brand');
			svg {
				path {
					stroke:map-get($colors,'brand');
				}
			}
			&:hover {
				background:map-get($colors,'brand');
				color:#FFF;
				svg {
					path {
						stroke:#FFF;
					}
				}
			}

			//Inverted White
			&.white {
				border-color:#FFF;
				color:#FFF;
				svg {
					path {
						stroke:#FFF;
					}
				}
				&:hover {
					background:#FFF;
					color:map-get($colors,'text');
					svg {
						path {
							stroke:map-get($colors,'text');
						}
					}
				}
			}

			//Inverted Dark
			&.dark {
				color:map-get($colors,'text');
				border-color:map-get($colors,'text');
				svg {
					path {
						stroke:map-get($colors,'text');
					}
				}
				&:hover {
					background:map-get($colors,'text');
					color:#FFF;
					svg {
						path {
							stroke:#FFF;
						}
					}
				}
			}
		}

		&:hover {

		}

	}

	//Button Container
	.buttons {
		display:flex;
		gap:10px;
	}