/**
	*
	* 		MODAL
	*			- Modal Styles
	*
	**/

.ReadModalPortal {
  .ReactModal {
    &__Overlay {
      background-color: rgba(0, 0, 0, 0.75);
    }
    &__Content {
      padding: 0;
      max-width: 800px;
      margin: 0 auto;
    }
  }
}
