/**
	*
	* 		Home
	*			- Styles specific to the home page should go here
	*
	**/
.App {
  text-align: center;
  min-width: 320px;
}

/* Header */
.App-logo {
  height: 40px;
  pointer-events: none;
}

.App-header {
  position: relative;
}

.App-header-inner {
  background-color: #00395d;
  display: flex;
  align-items: center;
  font-family: map-get($fonts, "headline");
  padding: 2px 30px;
  justify-content: space-between;
  position: relative;

  @media screen and (max-width: 560px) {
    padding: 15px;
  }

  nav li a {
    color: white;
    transition: color 0.2s ease;

    &:hover {
      color: #faa519;
    }
  }
}

.MobileNavIcon {
  height: 20px;
  cursor: pointer;

  @media screen and (min-width: 992px) {
    display: none;
  }
}

.Logo-Wrapper a {
  display: flex;
  align-items: center;
  gap: 8px;
}

.Wordmark {
  text-transform: uppercase;
  color: white;
  font-size: 1.5rem;
  letter-spacing: normal;

  @media screen and (max-width: 560px) {
    display: none;
  }

  @media screen and (max-width: 1300px) {
    font-size: 1rem;
  }
}

nav {
  display: flex;
  align-items: center;
  gap: 0 10px;

  svg {
    width: 17.5px;

    path {
      fill: white;
    }
  }

  ul {
    list-style-type: none;
    display: flex;
    font-family: "Roboto", sans-serif;
    color: white;
    text-transform: uppercase;
    font-size: 0.875rem;

    @media screen and (max-width: 992px) {
      display: none;
    }

    li {
      margin: 0 10px;
    }
  }
}

.Icon-Home {
  width: 18px;
}

.divider {
  border-left: 1px solid #fff;
  height: 24px;
  margin: 0 0.5em;
  color: #fff;

  @media screen and (max-width: 992px) {
    display: none;
  }
}

.Tools {
  display: flex;
  gap: 22px;
  flex-wrap: wrap;
  align-items: center;
  // margin-bottom: 80px;

  @media screen and (max-width: 820px) {
    flex-direction: column;
    padding-left: 26px;
    padding-right: 26px;
  }

  @media all and (min-width: 1440px) {
    gap: 24px;
  }

  @media all and (min-width: 1800px) {
    gap: 32px;
  }

  a {
    display: inline-block;

    img {
      display: block;
    }
  }
}

a.memberLogout {
  cursor: pointer;
}

@media screen and (max-width: 992px) {
  a.memberProfile,
  a.memberLogout {
    display: none;
  }
}

/* Main */
.Banner-Image {
  width: 100%;
  max-height: 700px;
  object-fit: cover;
  opacity: 0.6;
  display: block;
}

.Banner-Image-Wrapper {
  background: black;
}

.Main-Content {
  position: relative;

  @media screen and (max-width: 820px) {
    display: flex;
    flex-direction: column-reverse;
  }
}

.HeroContent {
  @media screen and (min-width: 821px) {
    margin-left: 400px; //calc(313px + 150px);
    position: absolute;
    top: 220px;
  }

  @media screen and (min-width: 1024px) {
    top: 320px;
    margin-left: calc(313px + 200px); //calc(313px + 5vw);
  }

  @media screen and (min-width: 1440px) {
    margin-left: 563px;
  }

  @media screen and (min-width: 1800px) {
    margin-left: calc(403px + 15vw);
  }

  color: white;
  box-sizing: border-box;
  width: -webkit-fill-available;
  text-align: left;
  padding: 0 30px;
}

.HeroContent h2 {
  font-weight: 600;
  font-size: 36px;
  text-transform: uppercase;
  font-style: normal;
  line-height: 42px;
  position: relative;
  font-family: map-get($fonts, "headline");
  margin: 0;
}

.HeroContent p {
  font-size: 20px;
}

.HeroContent .Button {
  margin: 0;
}

h3 {
  font-weight: 600;
  font-size: 24px;
  text-transform: uppercase;
  color: #003a5d;
  font-style: normal;
  line-height: 42px;
  position: relative;
  font-family: map-get($fonts, "headline");
  margin: 0;
}

h4 {
  margin: 0;
  color: #767678;
  font-family: "Roboto", sans-serif;
  text-transform: none;
}

.Dashboard {
  @media screen and (min-width: 821px) {
    position: absolute;
    //width: 263px;
    width: 300px;
    top: 43px;
    left: 80px;
    border-radius: 4px;
  }

  @media screen and (max-width: 1024px) {
    left: 5vw;
  }

  @media screen and (min-width: 1440px) {
    //width: 263px;
    left: 100px;
    padding: 40px;
  }

  @media screen and (min-width: 1800px) {
    width: 320px;
    left: 160px;
  }

  .Button {
    //width: 160px;
    box-sizing: border-box;
    width: 100%;
  }

  max-width: calc(90% - 90px);
  margin: 40px auto;

  padding: 32px;
  background-color: #f2f0f1;
  -webkit-box-shadow: 0px 0px 21px 0px rgb(157 157 157 / 42%);
  -moz-box-shadow: 0px 0px 21px 0px rgb(157 157 157 / 42%);
  box-shadow: 0px 0px 21px 0px rgb(157 157 157 / 42%);
}

.Dashboard > div {
  margin: 30px 0;
}

.Profile {
  .Profile-image {
    width: 140px;
    height: 146px;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
  }
  img {
    position: absolute;
    left: 50%;
    margin: 0 auto;
    z-index: 1;
    width: 140px;
    margin: 0 auto;
    height: auto;
    transform: translateX(-50%);

    &:first-child {
      mask-image: url("../../../assets/shield-mask.png");
      mask-size: 140px 146px;
    }
  }

  .Profile-shield {
    z-index: 2;
  }
}

.LifetimePoints {
  color: #767678;
  display: block;
  margin-bottom: 16px;
}

.BodyWrapper {
  margin-left: 0;

  @media screen and (min-width: 821px) {
    margin-left: 400px; //calc(313px + 5vw);
    padding: 0 0 0 60px;
  }

  @media screen and (min-width: 1025px) {
    margin-left: calc(313px + 150px); //403px;
  }

  @media screen and (min-width: 1440px) {
    margin-left: calc(413px + 150px);
  }

  @media screen and (min-width: 1800px) {
    margin-left: calc(313px + 15vw);
  }

  text-align: left;

  .Tools {
    margin-bottom: 80px;
  }
}

.Button {
  background-color: map-get($colors, "brand");
  color: #fff;
  text-decoration: none;
  transition: all 0.1s ease-in;
  padding: 1em 2em;
  font-size: 13px;
  font-weight: 500;
  min-width: 75px;
  display: block;
  margin: 0 auto;
  text-transform: uppercase;
  max-width: 160px;
  text-align: center;
}

.ButtonGroup {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

@media screen and (max-width: 820px) {
  .heroDesktop {
    display: none;
  }
}

.heroMobile {
  display: none;
  position: relative;

  @media screen and (max-width: 820px) {
    display: block;
  }

  .HeroContent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-left: 0;
    text-align: center;
    width: 100%;

    .Button {
      margin: 0 auto;
    }
  }

  .Banner-Image-Wrapper:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgb(0, 0, 0);
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0.9528186274509804) 20%,
      rgba(0, 0, 0, 0.8995973389355743) 40%,
      rgba(0, 0, 0, 0.8491771708683473) 60%,
      rgba(0, 0, 0, 0.7539390756302521) 70%,
      rgba(0, 0, 0, 0.6026785714285714) 80%,
      rgba(0, 0, 0, 0) 100%
    );
    width: 100%;
    height: 40%;
  }
}

.Share {
  background: map-get($colors, "brand");
  position: relative;
  margin-bottom: 30px;
  margin-top: -20px;

  @media screen and (max-width: 820px) {
    background: #00395d;
  }
}

.Share h3 {
  color: white;
  margin-left: 50px;

  @media screen and (max-width: 820px) {
    text-align: center;
    margin-left: 0;
    padding: 20px 20px;
    line-height: 1.2;
  }
}

.Share::before {
  content: "";
  background-image: url("../../../assets/bta-icon-share.png");
  background-size: 34px;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  background-color: #f2f1f1;
  border-radius: 50%;
  width: 64px;
  height: 64px;
  left: -32px;
  top: -12px;

  @media screen and (max-width: 820px) {
    display: none;
  }
}

.Tool {
  position: relative;
}

.Tool img {
  width: 100%;
  max-width: 220px;
  display: block;

  @media screen and (max-width: 820px) {
    max-width: none;
  }
  @media all and (min-width: 1440px) {
    max-width: 260px;
  }

  @media screen and (min-width: 1800px) {
    max-width: 320px;
  }
}

.Tool a {
  position: absolute;
  left: 10px;
  bottom: 10px;
}

.Blue {
  color: map-get($colors, "brand");
}

.Large {
  font-size: 32px;
  font-weight: 700;
}

hr {
  background-color: white;
  height: 2px;
  border: 0;
}

.Button:hover {
  background-color: #faa519;
}

dl {
  display: grid;
  grid-template-columns: 33% auto;
}

dt {
  grid-column: 1;
  text-align: left;
  padding: 4px 0 8px;
  border-top: 2px solid white;
  font-weight: 500;
  color: #767678;
}

dd {
  border-top: 2px solid white;
  grid-column: 2;
  padding: 4px 0 8px;
  text-align: right;
  margin-left: 0;
  color: map-get($colors, "brand");
  font-weight: 500;
}

.Footer {
  background: transparent;
  margin-top: 0;
  padding: 60px 0;
  min-height: 200px;
  display: none;

  img {
    width: 300px;
    height: auto;
    display: none;
  }
}

#learnMore {
  overflow-y: auto !important;
  overflow-x: hidden;
  max-width: 1240px;
}

#learnMore > div {
  display: flex;
  background: white;

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }

  img {
    max-width: 600px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;

    @media screen and (max-width: 820px) {
      max-width: none;
      max-height: 250px;
      object-position: center;
    }
  }

  h2 {
    color: #003a5d;
    font-weight: 600;
    font-size: 36px;
    text-transform: uppercase;
    font-style: normal;
    line-height: 42px;
    position: relative;
    font-family: map-get($fonts, "headline");
    margin: 0 0 15px 0;
  }

  > div.content {
    padding: 0 65px;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    min-width: 442px;
    margin: 40px 0;

    @media screen and (max-width: 820px) {
      text-align: center;
      padding: 30px 20px 65px;
      min-width: auto;
      margin: 0;
    }

    .Button {
      margin: 20px 0 0;

      @media screen and (max-width: 820px) {
        margin: 20px auto 0;
      }
    }

    p {
      margin: 0.5em 0;
    }
  }
}

.lity-container {
  max-width: 80vw;

  @media screen and (max-width: 820px) {
    max-width: 95vw;
  }
}

.lity {
  background: rgba(0, 0, 0, 0.5);
}

.lity-close {
  position: absolute;
  color: black;

  &:hover {
    color: black;
  }
}

.mobileNav {
  display: none;
  transition: max-height 0.2s ease;
  flex-direction: column;
  z-index: 30;
  background: #00395d;
  margin: 0 31px;
  border-top: 3px solid #0091cb;

  @media screen and (max-width: 820px) {
    &.active {
      display: flex;
      position: absolute;
      top: 100%;
      left: 0;
      width: calc(100% - 62px);
      // height: 100%;
      // height: fit-content;

      opacity: 1;
    }
  }

  ul,
  .Tools {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    text-align: left;
    width: 100%;

    .memberProfile,
    .memberLogout {
      display: block;
    }
  }

  ul li {
    padding: 10px 0;
    margin: 0 19px;

    a {
      color: white;
      font-size: 27px;
      font-weight: 700;
      text-transform: uppercase;
      font-family: map-get($fonts, "headline");
      line-height: 1.5;
      padding: 0.5em 0;
    }
  }

  .Tools {
    width: 100%;
    padding: 0 0 0 34px;
    margin-bottom: 30px;
    align-items: start;
  }
}

.lity-close {
  background: white;
  border-radius: 50%;
  right: 20px;
  top: 10px;
  z-index: 100;

  svg .bg {
    fill: #e7e7e7;
  }

  &:hover {
    svg .bg {
      fill: white;
    }

    background: white;
  }
}

.shareModal {
  background: white;
  max-width: 800px;
  overflow-y: auto !important;
  overflow-x: hidden;

  img {
    width: 100%;
    opacity: 0.6;
    display: block;
    max-height: 320px;
    object-fit: cover;

    @media screen and (max-width: 820px) {
      min-height: 300px;
    }
  }

  input,
  select {
    padding: 15px 20px;
    border-radius: 0;
    border: 0;
    box-shadow: 0px 0px 21px 0px rgba(157, 157, 157, 0.42);
    font-size: 14px;
  }

  select {
    height: fit-content;
    -webkit-appearance: none;
    appearance: none;
    background: white
      url("data:image/svg+xml,%3Csvg class='svg-icon' style='width: 1em; height: 1em;vertical-align: middle;fill: %23727272;overflow: hidden;' viewBox='0 0 1024 1024' version='1.1' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M952.16851 329.603857l-61.405535-60.778248c-8.91402-8.840342-23.154349-9.061376-31.774681-0.517793L513.860882 597.050048 161.772942 254.480902c-8.729825-8.655124-23.12058-8.43409-32.108279 0.481977l-61.998029 61.367673c-9.025561 8.951883-9.248641 23.156396-0.517793 31.81152l430.986895 419.334507c8.691963 8.656147 23.083741 8.433066 32.108279-0.518816l61.997006-61.367673c1.418302-1.404999 2.589988-2.952238 3.572362-4.579295l356.838128-339.929016C961.306634 352.539218 961.084577 338.40736 952.16851 329.603857z' /%3E%3C/svg%3E%0A")
      no-repeat 95% center;
    // background: white url("../../../assets/dropdown-64.png") no-repeat 95% center;
    background-size: 16px;
    width: 100px;
    color: #727272;
    /*Width of select dropdown to give space for arrow image*/
  }

  .Button {
    margin-left: 0;
    cursor: pointer;

    @media screen and (max-width: 820px) {
      margin-left: auto;
    }
  }

  .Button.disabled:hover {
    background-color: #767678;
  }

  .Button.disabled {
    background-color: #767678;
    cursor: not-allowed;
  }
  

  .share-header {
    position: relative;

    > div {
      position: absolute;
      bottom: 30px;
      left: 60px;

      @media screen and (max-width: 820px) {
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        padding: 0 20px;
        text-align: center;
      }

      h2 {
        color: white;
        font-size: 42px;
        text-transform: uppercase;
        margin-bottom: 10px;
        line-height: 1.1;

        @media screen and (max-width: 820px) {
          font-size: 32px;
        }
      }

      h3 {
        color: white;
        font-family: map-get($fonts, "primary");
        text-transform: none;
        font-weight: 400;
        max-width: 80%;
        line-height: 1.1;

        @media screen and (max-width: 820px) {
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }

  .success {
    text-align: center;
    padding: 20px 0;
    .icon {
      font-size: 60px;
      padding-bottom: 10px;
    }
    h2 {
      font-family: map-get($fonts, "headline");
      color: #003a5d;
      font-size: 35px;
      text-transform: uppercase;
      font-weight: 600;
    }
    p {
      font-size: 20px;
      max-width: 400px;
      margin: 0 auto 30px;
    }
    .button {
      font-size: 20px;
    }
  }

  form {
    margin-bottom: 40px;

    > div {
      display: flex;
      gap: 40px;
      margin-bottom: 20px;
      align-items: center;

      @media screen and (max-width: 820px) {
        flex-direction: column;
        gap: 20px;
      }

      p {
        @media screen and (max-width: 820px) {
          text-align: center;
        }
      }

      > * {
        width: 50%;
        box-sizing: border-box;

        @media screen and (max-width: 820px) {
          width: 100%;
        }

        &.fullwidth {
          width: 100%;
        }
      }

      [type="submit"] {
        width: 100%;
      }

      .error {
        color: #f00;
        width: 100%;
      }
    }
  }

  .content {
    padding: 30px 60px;

    @media screen and (max-width: 820px) {
      padding: 20px;
    }
  }

  .copyCopy {
    font-size: 15px;
    font-weight: 600;
    line-height: 1.3;
    text-align: left;
    max-width: 100%;
    margin: 10px 10px 30px 10px;
  }

  .modalFooter {
    padding: 10px 20px;
    background: #e1dfe0;
    text-align: left;
    font-size: 14px;
    line-height: 1.2;
    color: #013c61;
  }

  .shareCopy {
    position: relative;
    width: 100%;

    input:not(.Button) {
      width: 100%;
      box-sizing: border-box;
      background-color: #e1dfe0;
    }

    .Button {
      position: absolute;
      right: 0;
      top: 0;

      &.disabled {
        cursor: not-allowed;
        &:hover {
          background-color: #0091cb;
        }
      }
    }
  }
}

#podcastShare .Banner-Image-Wrapper {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    background: rgb(0, 0, 0, 0.25);
  }
}

#classesShare .Banner-Image-Wrapper {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    background: rgb(0, 0, 0, 0.25);
  }
}

#toolsShare .Banner-Image-Wrapper {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    background: rgb(0, 0, 0, 0.25);
  }
}
