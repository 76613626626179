
	/**
	*
	* 		Layout
	*			- Grid and Layout styles for the website
	*
	**/
	:root {
		--container-padding:26px;
		--wrap-padding:50px;
	}

	@media all and (max-width:500px){
		:root {
			--container-padding:15px;
			--wrap-padding:15px;
		}
	}