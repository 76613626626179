.alertify .ajs-body,
.alertify .ajs-footer .ajs-buttons.ajs-primary {
  text-align: center;
}

.alertify .ajs-footer {
  padding: 4px 36px;
}

.ajs-button.ajs-cancel,
.ajs-button.ajs-ok,
button {
  background: none;
  outline: none;
  border: none;
}

.ajs-button.ajs-ok {
  background-color: #00395d;
  color: #fff;
}

.ajs-button.ajs-cancel,
.btn-cancel {
  border: 1px solid #cecece;
  color: #cecece;
  cursor: pointer;
}

.ajs-button.ajs-cancel,
.ajs-button.ajs-ok,
.btn-bordered,
.btn-cancel,
.btn-light-blue,
.btn-main,
.btn-save {
  text-transform: uppercase;
  padding: 0.7em 2em;
  transition: all .1s ease-in;
  font-size: 13px;
  font-weight: 300;
  min-width: 75px;
}

.ajs-button.ajs-ok:hover,
.btn-main:hover,
.btn-save:hover {
  background-color: #faa519;
  color: #fff;
}

.alertify .ajs-header {
  display: none;
}
