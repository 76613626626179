.bounce {
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(30px);
  }

  60% {
    transform: translateY(15px);
  }
}

.Referrals {
  background-color: #f2f0f1;
  padding-bottom: 80px;
  text-align: left;

  + .Footer {
    display: none;
  }

  .Header {
    background-color: #00395d;
    position: relative;
    max-height: 40vh;
    overflow: hidden;

    h2 {
      position: absolute;
      left: 50%;
      top: 40px;
      transform: translateX(-50%);
      color: white;
      font-size: 32px;
      width: 100%;
      text-align: center;
      display: none;

      @media screen and (max-width: 820px) {
        display: block;
      }
    }

    img {
      display: block;
      width: 100%;
      opacity: 0.85;

      @media screen and (max-width: 820px) {
        height: 220px;
        object-fit: cover;
      }
    }
  }

  h1 {
    text-transform: uppercase;
    color: white;
  }

  .referralsProfile {
    max-width: 1400px;
    margin: -60px auto 0;
    width: 80vw;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;

    .Profile-image {
      width: 200px;
      height: 209px;
      margin: 0 auto;
      position: relative;
      overflow: hidden;
      img {
        position: absolute;
        left: 50%;
        margin: 0 auto;
        z-index: 1;
        width: 200px;
        margin: 0 auto;
        height: auto;
        transform: translateX(-50%);

        &:first-child {
          mask-image: url("../../../assets/shield-mask.png");
          mask-size: 200px 208px;
        }
      }
    }

    .Profile-shield {
      mask-image: none;
      z-index: 2;
    }

    .Profile-default {
      max-width: 200px;
      min-width: 150px;
      width: 15vw;
      margin-top: -1vw;
      @media screen and (max-width: 820px) {
        margin: 0 auto;
      }
    }

    @media screen and (max-width: 820px) {
      width: 100vw;
      flex-direction: column;
    }

    .Button {
      margin: 0 10px 0 auto;
      background: map-get($colors, "highlight");
      &:hover {
        background: map-get($colors, "brand");
      }
      @media screen and (max-width: 820px) {
        margin: 30px auto 0;
      }
    }

    .successfulReferrals {
      @media screen and (max-width: 1000px) {
        display: none;
      }

      > span {
        border: 1px solid #ccc;
        padding: 12px;
        font-weight: 600;
        color: #767678;
      }
    }

    > div {
      display: flex;
      align-items: flex-end;
      gap: 20px;

      @media screen and (max-width: 820px) {
        gap: 0;
        flex-direction: column;
        text-align: center;
        margin: 0 auto;
      }

      > div > span {
        font-weight: 600;
        color: #767678;
      }
    }
  }

  h2 {
    color: #00395d;
    text-transform: uppercase;
    margin-bottom: 6px;
  }

  .referralsList {
    background-color: white;
    max-width: 1400px;
    width: 80vw;
    margin: 80px auto 0;
    padding: 26px;
    margin-bottom: 40px;

    thead {
      font-family: map-get($fonts, 'primary');
    }

    @media screen and (max-width: 820px) {
      margin-top: 40px;
    }

    > h2 {
      @media screen and (max-width: 820px) {
        display: none;
      }
    }

    > span {
      color: #767678;
      font-weight: 600;

      @media screen and (max-width: 820px) {
        text-align: center;
        display: block;
      }
    }

    > h2,
    > span {
      padding-left: 20px;

      @media screen and (max-width: 820px) {
        padding-left: 0;
      }
    }

    img {
      display: none;

      @media screen and (max-width: 820px) {
        display: block;
        margin: 10px auto 0;
        width: 24px;
      }
    }
  }

  p.noData {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    padding: 100px 0;
    text-align: center;
    color: #767678;
  }

  table {
    border-spacing: 0;
    width: 100%;
    margin-top: 10px;

    thead {
      color: #767678;
      font-family: map-get($fonts, "primary");
      text-transform: uppercase;

      @media screen and (max-width: 820px) {
        display: none;
      }

      th {
        padding: 20px 10px 10px 20px;
        text-align: left;
        text-transform: none;

        &:last-child {
          text-align: right;
          padding-right: 20px;
        }
      }
    }

    td {
      font-family: map-get($fonts, "headline");
    }

    th {
      font-weight: 600;
    }

    td {
      padding: 30px 20px;
      text-align: left;

      &:last-child {
        text-align: right;
      }

      span.referralCompany {
        font-family: map-get($fonts, "primary");
        font-weight: 400;
        color: #767678;
      }

      span.referralDate {
        font-family: map-get($fonts, "primary");
        font-weight: 600;
        color: #767678;
      }

      img {
        border-radius: 50%;
        width: 50px;
        display: block;
      }
    }

    tr td,
    th {
      border-bottom: 1px solid #ccc;
    }

    tr {
      @media screen and (max-width: 820px) {
        border-bottom: 1px solid #ccc;
        display: flex;
        flex-direction: column;
        padding: 20px 0;

        td {
          text-align: center;
          border: 0;
          padding: 10px;

          &:last-child {
            text-align: center;
            padding-top: 0;
          }
        }
      }
    }
  }

  .pip-Successful,
  .pip-Pending,
  .pip-Unsuccessful {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 10px;
  }

  .referralStatus {
    border: 1px solid #ccc;
    padding: 12px;
    width: fit-content;
    font-weight: 600;
    color: #767678;
    font-family: map-get($fonts, "primary");

    @media screen and (max-width: 820px) {
      margin: 0 auto;
    }
  }

  .pip-Successful {
    background-color: #1292cc;
  }

  .pip-Pending {
    background-color: #fba51c;
  }

  .pip-Unsuccessful {
    background-color: #003b5d;
  }
}
