.Leaderboard {
  background: #f2f0f1;
  padding-bottom: 80px;

  .Header {
    background-color: #00395d;
    padding: 60px 0 180px;

    @media screen and (max-width: 820px) {
      padding: 40px 0 120px;
    }
  }

  h1 {
    text-transform: uppercase;
    color: white;
    line-height: 1.2;
  }

  .Button.back {
    margin: 30px auto 0;
    background: map-get($colors, "highlight");
    &:hover {
      background: map-get($colors, "brand");
    }
  }

  table {
    height: 1px;
    max-width: 1400px;
    width: 80vw;
    margin: 50px auto 0;
    padding-bottom: 40px;
    border-spacing: 0;
    border-collapse: separate;
    border-spacing: 0 10px;

    @media screen and (max-width: 820px) {
      width: 90vw;
      margin-top: 0;
    }

    thead {
      background-color: #00395d;
      color: white;
      font-family: map-get($fonts, "headline");
      text-transform: uppercase;

      @media screen and (max-width: 820px) {
        display: none;
      }

      th {
        padding: 20px 10px 10px;

        &:nth-child(2) {
          text-align: left;
        }

        span {
          display: block;
          font-weight: 300;
          color: #faa51b;
          text-transform: none;
          font-family: map-get($fonts, "primary");
          margin-bottom: 10px;

          strong {
            font-weight: 600;
          }
        }
      }
    }

    tr {
      height: 100%;
    }

    td,
    th {
      font-family: map-get($fonts, "headline");

      &:first-child {
        display: none;

        @media screen and (max-width: 820px) {
          display: block;
          padding: 10px 5px;
          color: white;
          background-color: #003b5d;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
        }
      }

      &:nth-child(2) {
        text-align: left;
      }

      &:nth-child(4),
      &:nth-child(3) {
        @media screen and (max-width: 820px) {
          display: none;
        }
      }
    }

    td {
      padding: 10px;

      img {
        border-radius: 50%;
        width: 50px;
        height: 50px;
        display: block;
      }
    }
  }

  .currentPoints {
    width: 90vw;
    display: none;
    justify-content: space-between;
    margin: 30px auto 10px;
    padding: 10px;
    background-color: #003b5d;
    color: white;
    box-sizing: border-box;

    @media screen and (max-width: 820px) {
      display: flex;
    }
  }

  .Standings {
    max-width: 1200px;
    margin: -140px auto 0;
    display: flex;
    gap: 20px;
    align-items: flex-end;
    justify-content: center;

    @media screen and (max-width: 820px) {
      gap: 10px;
      margin-top: -80px;
    }

    .Profile-image {
      position: relative;
      img {
        width: auto;
        max-width: 132px;
        object-fit: cover;
        max-height: 140px;
        mask-image: url("../../../assets/shield-mask.png");
        mask-size: 100%;

        @media screen and (max-width: 820px) {
          max-height: 84px;
          max-width: 80px;
        }
      }

      &.first {
        position: relative;

        img {
          max-width: 180px;
          max-height: 200px;

          @media screen and (max-width: 820px) {
            height: 110px;
            mask-size: 110px 110px;
          }
        }

        @media screen and (max-width: 820px) {
          > .Profile-shield {
            width: 110px;
          }
        }
        .Profile-shield {
          top: 1px;
        }
      }

      .Profile-shield {
        position: absolute;
        left: 50%;
        margin: 0 auto;
        z-index: 1;
        margin: 0 auto;
        height: auto;
        top: 0;
        transform: translateX(-50%);
        z-index: 2;
      }
    }
    // img {
    //   position: absolute;
    //   left: 50%;
    //   margin: 0 auto;
    //   z-index: 1;
    //   width: 140px;
    //   margin: 0 auto;
    //   height: auto;
    //   transform: translateX(-50%);
    // }

    // .Profile-shield {
    //   z-index: 2;
    // }

    h2 {
      font-size: 14px;
      text-transform: uppercase;
      color: #8a8b8c;
      line-height: 1.2;
    }
  }

  .LeaderboardProfile {
    display: flex;
    align-items: center;
    gap: 10px;

    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .Name {
      color: #00395d;
      text-transform: uppercase;
    }

    .Company {
      color: #767678;
      font-family: map-get($fonts, "primary");
    }
  }

  tbody tr {
    transition: all 0.2s ease;
    background: white;
    margin: 10px 0;
    perspective: 1px;
    transition: transform 0.2s ease;

    td.data {
      color: #767678;
      font-family: map-get($fonts, "primary");
      font-weight: 600;
    }

    &:hover {
      background-color: map-get($colors, "brand");
      transform: scale(1.025);

      td,
      .Name,
      .Company {
        color: white;
        background-color: map-get($colors, "brand");
      }

      td:nth-child(3) {
        color: #faa51b;
      }
    }
  }
}
