
	/**
	*
	* 		GENERAL
	*			- General supporting Styles
	*
	**/
		
	body {
		@each $key, $value in $primary {
			#{"" + $key}: $value ;
		}
	}

	::selection {
		background:map-get($colors,'sage');
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-family:map-get($fonts,'headline');
		text-transform:lowercase;
		letter-spacing:-0.52px;
		leading-trim:both;
		text-edge:cap;
	}

	a {
		color:map-get($colors,'text');
	}

	@each $key, $value in $colors {
		.bg#{$key} {
			background: $value;
		}
		.color#{$key} {
			color: $value;
		}
	}